<template>
  <div id="merchant-page">
    <div class="flex items-center justify-between mb-4">
      <h2>{{$t('FC Storing Agent')}}</h2>
      <span class="font-medium text-primary cursor-pointer">
        <vs-button
          @click="openAddUserModal('add')"
          color="primary"
          icon-pack="feather"
          icon="icon-plus"
          size="large"
        >
        </vs-button>
      </span>
    </div>

    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      :data="fcStoring"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >
      <template slot="thead">
        <shipblu-th>{{$t('Name')}}</shipblu-th>
        <shipblu-th>{{$t('Email')}}</shipblu-th>
        <shipblu-th class="order-1 flex justify-center">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="order-3" :data="data[indextr].name">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Name')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].email }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-3" :data="data[indextr].email">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Email')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].email }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-2" style="vertical-align: middle;">
            <div class="md:text-center text-right">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span>
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="openAddUserModal('edit', data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Edit')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="resetPassword(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="EditIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Change Password')}}</span>
                    </span>
                  </vs-dropdown-item>
                  <vs-dropdown-item
                    @click="deleteFcStoring(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="TrashIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Delete')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <add-fc-storing-agent :type="type" :data="fcStoringAgent" :addUserModal="addUserModal"
    @addUserModal="addUserModal = $event" @loadData="loadData"></add-fc-storing-agent>
    <reset-password userRole="fc-storing-agent" :resetPass="resetPass" :userData="userData" @loadData="loadData" @resetPass="resetPass = $event"></reset-password>
  </div>
</template>

<script>
import AddFcStoringAgent from './components/AddFcStoringAgent.vue'
import ResetPassword from './components/FulfillmentResetPassword.vue'
import i18nData from '../../../i18n/i18nData.js'
import ShipbluTable from '../../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../../layouts/components/ShipBluTd.vue'
import { sendFulfillmentRequest } from '../../../http/axios/requestHelper'

export default {
  data () {
    return {
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      selected: [],
      fcStoring: [],
      deleteData: {},
      addUserModal: false,
      type: '',
      fcStoringAgent: {},
      resetPass: false,
      tableLoader: false,
      userData: {}
    }
  },
  methods: {
    loadData () {
      this.tableLoader = true
      sendFulfillmentRequest(true, false, this, 'api/v1/fc/users/fc-storing-agent/', 'get', null, true,
        (response) => {
          this.fcStoring = response.data
          this.fcStoring = this.fcStoring.reverse()
          this.tableLoader = false
        }
      )
    },
    openAddUserModal (type, data) {
      this.type = type
      if (type === 'add') {
        this.fcStoringAgent = {}
      } else {
        this.fcStoringAgent = data
      }
      this.addUserModal = true
    },
    deleteFcStoring (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendFulfillmentRequest(false, false, this, `api/v1/fc/users/fc-storing-agent/${this.deleteData.user_id}/`, 'delete', null, true,
        () => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['FC Storing Agent'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadData()
        }
      )
    },
    resetPassword (data) {
      this.resetPass = true
      this.userData = data
    }
  },
  created () {
    this.loadData()
  },
  components: {
    AddFcStoringAgent,
    ResetPassword,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
